<template>
  <div class="d-flex flex-column flex-grow-1">
    <!--begin::Content header-->
    <div class="text-right d-flex justify-content-center">
      <div
        class="text-right d-flex justify-content-end pt-5 pb-lg-0 pb-10 mb-lg-5"
      >
        <span class="font-size-h5">
          {{ $t("REGISTER.HAVE") }}
        </span>
        <router-link
          class="text-info font-weight-bolder font-size-h5 ml-2"
          :to="{ name: 'login' }"
        >
          {{ $t("LOGIN.LOGIN") }}
        </router-link>
      </div>
    </div>
    <!--end::Content header-->

    <!--begin::Signup-->
    <div class="d-flex flex-row-fluid flex-center">
      <div class="login-form login-signin">
        <div class="text-center">
          <h1 class="font-size-h1 font-weight-bolder text-dark">
            {{ $t("LOGIN.REGISTER") }}
          </h1>
          <p class="text-muted font-weight-bold font-size-h4">
            {{ $t("REGISTER.ENTER") }}
          </p>
        </div>

        <b-card no-body>
          <b-tabs content-class="mt-3" fill card>
            <b-tab :title="$t('REGISTER.EMPLOYER')">
              <RegisterEmployer></RegisterEmployer>
            </b-tab>
          </b-tabs>
        </b-card>
      </div>
    </div>

    <!--end::Signup-->
  </div>
</template>

<style lang="scss" scoped>
.spinner.spinner-right {
  padding-right: 3.5rem !important;
}
</style>

<script>
import RegisterEmployer from "@/modules/auth/components/register/RegisterEmployer";

export default {
  components: { RegisterEmployer },
  name: "register",
};
</script>
