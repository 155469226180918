<template>
  <b-form v-on:submit.prevent="registerEmployer">
    <!-- begin: personal details      -->
    <h5 class="text-muted mb-10">{{ $t("EMPLOYEEFORM.PERSONAL") }}</h5>
    <div class="d-flex flex-grow-1">
      <!-- begin: Name Input -->
      <b-form-group
        id="employer-name-input-group"
        :label="$t('EMPLOYEETABLE.FULLNAME')"
        class="mr-4"
        label-for="employer-name-input"
        label-class="font-weight-bolder"
      >
        <b-form-input
          class="form-control form-control-solid h-auto py-5 px-6"
          id="employer-name-input"
          name="employer-name-input"
          v-model="$v.form.username.$model"
          :state="validateState('username')"
          aria-describedby="employer-name-input-live-feedback"
          :placeholder="$t('LOGIN.USERNAME')"
        ></b-form-input>
        <!-- end: Name Input -->

        <b-form-invalid-feedback v-if="!$v.form.username.required">
          {{ $t("REGISTER.REQUIRED") }}
        </b-form-invalid-feedback>

        <b-form-invalid-feedback v-if="!$v.form.username.maxLength">
          Must not be more than
          {{ $v.form.username.$params.maxLength.max }} characters
        </b-form-invalid-feedback>

        <b-form-invalid-feedback v-if="!$v.form.username.minLength">
          Must be at least
          {{ $v.form.username.$params.minLength.min }} characters
        </b-form-invalid-feedback>
      </b-form-group>

      <!-- begin:Email Input -->
      <b-form-group
        id="employer-email-input-group"
        :label="$t('EMPLOYEESGENERAL.MESSAGE.EMAIL')"
        label-for="employer-email-input"
        label-class="font-weight-bolder"
      >
        <b-form-input
          class="form-control form-control-solid h-auto py-5 px-6"
          id="employer-email-input"
          name="employer-email-input"
          v-model="$v.form.email.$model"
          :state="validateState('email')"
          aria-describedby="employer-email-input-live-feedback"
          :placeholder="$t('EMPLOYEESGENERAL.MESSAGE.EMAIL')"
        ></b-form-input>

        <b-form-invalid-feedback id="employer-email-input-live-feedback">
          {{ $t("REGISTER.REQUIRED2") }}
        </b-form-invalid-feedback>
      </b-form-group>
    </div>

    <b-form-group
      id="employer-password-input-group"
      :label="$t('LOGIN.PASSWORD')"
      label-for="employer-password-input"
      label-class="font-weight-bolder"
    >
      <b-form-input
        class="form-control form-control-solid h-auto py-5 px-6"
        type="password"
        id="employer-password-input"
        name="employer-password-input"
        v-model="$v.form.password.$model"
        :state="validateState('password')"
        :placeholder="$t('LOGIN.PASSWORD')"
      ></b-form-input>

      <b-form-invalid-feedback v-if="!$v.form.password.required">
        {{ $t("LOGIN.REQUIRED2") }}
      </b-form-invalid-feedback>

      <b-form-invalid-feedback v-if="!$v.form.password.minLength">
        Password must be at least 6 characters
      </b-form-invalid-feedback>
    </b-form-group>
    <!-- end: personal details      -->

    <hr class="my-5" />

    <!-- begin: company details      -->
    <h5 class="text-muted my-10">{{ $t("SIDEBAR.COMPANY") }}</h5>

    <!--company name  -->
    <b-form-group
      id="company-name-input-group"
      label="Company Name"
      label-for="company-name-input"
      label-class="font-weight-bolder"
    >
      <b-form-input
        class="form-control form-control-solid h-auto py-5 px-6"
        type="text"
        id="company-name-input"
        name="company-name-input"
        v-model="$v.form.companyName.$model"
        :state="validateState('companyName')"
        aria-describedby="company-name-input-live-feedback"
        placeholder="Company Name"
      ></b-form-input>

      <b-form-invalid-feedback v-if="!$v.form.companyName.required">
        Company Name Required
      </b-form-invalid-feedback>

      <b-form-invalid-feedback v-if="!$v.form.companyName.maxLength">
        Must not be more than 255 characters
      </b-form-invalid-feedback>

      <b-form-invalid-feedback v-if="!$v.form.companyName.minLength">
        Must be at least
        {{ $v.form.companyName.$params.minLength.min }} characters
      </b-form-invalid-feedback>
    </b-form-group>

    <!--company no  -->
    <b-form-group
      id="company-no-input-group"
      label="Company Registration No"
      label-for="company-no-input"
      label-class="font-weight-bolder"
    >
      <b-form-input
        class="form-control form-control-solid h-auto py-5 px-6"
        type="text"
        id="company-no-input"
        name="company-no-input"
        v-model="$v.form.companyNo.$model"
        :state="validateState('companyNo')"
        aria-describedby="company-no-input-live-feedback"
        placeholder="Company Registration No"
      ></b-form-input>

      <b-form-invalid-feedback v-if="!$v.form.companyNo.required">
        This email is required
      </b-form-invalid-feedback>

      <b-form-invalid-feedback v-if="!$v.form.companyNo.maxLength">
        Must not be more than 30 characters
      </b-form-invalid-feedback>

      <b-form-invalid-feedback v-if="!$v.form.companyNo.minLength">
        Must be at least 3 characters
      </b-form-invalid-feedback>
    </b-form-group>

    <!--company email  -->
    <b-form-group
      id="company-email-input-group"
      label="Company Email"
      label-for="company-email-input"
      label-class="font-weight-bolder"
    >
      <b-form-input
        class="form-control form-control-solid h-auto py-5 px-6"
        type="text"
        id="company-email-input"
        name="company-email-input"
        v-model="$v.form.companyEmail.$model"
        :state="validateState('companyEmail')"
        aria-describedby="company-email-input-feedback"
        placeholder="ecample@example.com"
      ></b-form-input>

      <b-form-invalid-feedback v-if="!$v.form.companyEmail.required">
        {{ $t("REGISTER.REQUIRED3") }}
      </b-form-invalid-feedback>

      <b-form-invalid-feedback v-if="!$v.form.companyEmail.email">
        Must use email format: example@example.com
      </b-form-invalid-feedback>
    </b-form-group>

    <!--company contact  -->
    <b-form-group
      id="company-contact-input-group"
      label="Company Contact No"
      label-for="company-contact-input"
      label-class="font-weight-bolder"
    >
      <b-form-input
        class="form-control form-control-solid h-auto py-5 px-6"
        type="text"
        id="company-contact-input"
        name="company-contact-input"
        v-model="$v.form.companyContact.$model"
        :state="validateState('companyContact')"
        aria-describedby="company-contact-input-feedback"
        placeholder="0123456789"
      ></b-form-input>

      <b-form-invalid-feedback v-if="!$v.form.companyContact.required">
        Required
      </b-form-invalid-feedback>

      <b-form-invalid-feedback v-if="!$v.form.companyContact.minLength">
        Must be minimum of 9 digits
      </b-form-invalid-feedback>

      <b-form-invalid-feedback v-if="!$v.form.companyContact.maxLength">
        Must not be more than 12 digits
      </b-form-invalid-feedback>
    </b-form-group>
    <!-- end: company details      -->

    <!--begin::Action-->
    <div
      class="form-group d-flex flex-wrap flex-center justify-content-between"
    >
      <button
        v-on:click="$router.push('login')"
        class="btn btn-light font-weight-bold font-size-h6 px-9 py-4 my-3"
      >
        {{ $t("BUTTON.CANCEL") }}
      </button>
      <button
        type="submit"
        ref="employer_registration_submit"
        class="btn btn-primary font-weight-bolder font-size-h6 px-9 py-4 my-3"
      >
        {{ $t("COMPANY.REGISTRATION.SUBMIT") }}
      </button>
    </div>
    <!--end::Action-->
  </b-form>
</template>

<style lang="scss" scoped>
.spinner.spinner-right {
  padding-right: 3.5rem !important;
}
</style>

<script>
import { mapState } from "vuex";
import { REGISTER } from "@/modules/auth/store/register/employer_registration";
import { validationMixin } from "vuelidate";
import {
  email,
  minLength,
  required,
  maxLength,
} from "vuelidate/lib/validators";
import swal from "sweetalert";

export default {
  name: "RegisterEmployer",
  mixins: [validationMixin],
  data() {
    return {
      form: {
        username: "",
        email: "",
        password: "",
        companyName: "",
        companyNo: "",
        companyContact: "",
        companyEmail: "",
      },
    };
  },
  validations: {
    form: {
      username: {
        required,
        maxLength: maxLength(255),
        minLength: minLength(3),
      },
      email: {
        required,
        email,
      },
      password: {
        required,
        minLength: minLength(6),
        maxLength: maxLength(30),
      },
      companyEmail: {
        required,
        email,
        maxLength: maxLength(255),
      },
      companyName: {
        required,
        maxLength: maxLength(255),
        minLength: minLength(5),
      },
      companyNo: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(30),
      },
      companyContact: {
        required,
        minLength: minLength(9),
        maxLength: maxLength(12),
      },
    },
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    resetForm() {
      this.form = {
        username: null,
        email: null,
        password: null,
        companyName: null,
        companyEmail: null,
        companyNo: null,
        companyContact: null,
      };

      this.$nextTick(() => {
        this.$v.$reset();
      });
    },

    /**
     * @param error
     */
    validationError(error) {
      const val = error.response.data.errors;
      const h = this.$createElement;
      const password = val["password"]
        ? val["password"].map((id) => {
            return h("p", { class: ["text-center"] }, id);
          })
        : [h("p", { class: ["text-center"] })];
      const messageVNode = h("div", { class: ["foobar"] }, [
        h("p", { class: ["text-center"] }, [val["email"]]),
        h("p", { class: ["text-center"] }, [val["company_email"]]),
        ...password,
      ]);
      this.$bvModal.msgBoxOk(messageVNode, {
        title: "Please check the entered form",
        centered: true,
        okVariant: "danger",
        bodyClass: "h4",
        footerBgVariant: "light",
      });
    },

    registerEmployer() {
      this.$v.form.$touch();

      if (this.$v.form.$anyError) {
        return;
      }

      const name = this.$v.form.username.$model;
      const email = this.$v.form.email.$model;
      const password = this.$v.form.password.$model;
      const company_name = this.$v.form.companyName.$model;
      const company_number = this.$v.form.companyNo.$model;
      const company_contact = this.$v.form.companyContact.$model;
      const company_email = this.$v.form.companyEmail.$model;

      // set spinner to submit button
      const submitButton = this.$refs["employer_registration_submit"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");

      this.$store
        .dispatch(REGISTER, {
          email: email,
          password: password,
          name: name,
          company_name: company_name,
          company_number: company_number,
          company_contact: company_contact,
          company_email: company_email,
        })
        .then(() => {
          swal({
            title: "Registration successful",
            text: "Please Login using email and password",
            icon: "success",
          });
          this.$router.push({ name: "login" });
        })
        .catch((error) => {
          // Handles other than validation errors.
          console.error("Error!", error.response);
          console.log("Error", error.response.data);
          if (!error.response.data.errors)
            swal("Registration Failed", "Server Error detected.", "error");
          else {
            this.validationError(error);
          }
        })
        .finally(() =>
          submitButton.classList.remove(
            "spinner",
            "spinner-light",
            "spinner-right"
          )
        );
    },
  },
  computed: {
    ...mapState({
      errors: (state) => state.auth.employer.errors,
    }),
  },
};
</script>

<style scoped></style>
